import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import './index.css'
import { LanguageContext } from '../../lang'

const Thank_You = () => {
  const {
    translations: { thank_you }
  } = useContext(LanguageContext)
  const { thank_YouText, buttonText } = thank_you
  return (
    <section id='book-form'>
      <section id='not-found-section'>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-lg-2 col-md-12 text-center not-found-img-bg p-4'>
              <img
                className='img-fluid not-found-img'
                src={require('../../images/notfound/not-found-img.png')}
              />
            </div>

            <div className='col-lg-6 col-md-12 bg-white p-4'>
              <p className='not-found-text p-3'>{thank_YouText}</p>
              <div className='not-found-btn-wrap mt-4 pl-3'>
                <a onClick={() => navigate('/')} className='btn try-again-btn'>
                  {buttonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

export default Thank_You
