import React, { Component } from 'react'
import Thank_You from '../components/thank-you'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'

const Thankyou = () => {
	return (
		<Layout>
			<SEO title='Thankyou' />
			<Thank_You />
		</Layout>
	)
}

export default Thankyou
